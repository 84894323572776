import { IonIcon } from '@ionic/react'
import { chatbubbleOutline } from 'ionicons/icons'
import React from 'react'
import { MotionBox } from '../../Motion'
import { Flex } from '@chakra-ui/layout'
import { Box } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

interface CommentProps {
  commentCount: number
  postId: string | number
  onClick?: () => void
}

export const Comment: React.FC<CommentProps> = ({ commentCount, postId, onClick }) => {
  return (
    <Box as={RouterLink} to={`/posts/${postId}`}>
      <Flex
        justifyContent="center"
        alignItems="center"
        onClick={onClick}
        cursor="pointer"
        userSelect="none"
        transition="color .35s"
        _hover={{ color: 'blue.400' }}
      >
        <MotionBox whileTap={{ scale: 0.8 }}>
          <IonIcon size="large" icon={chatbubbleOutline}/>
        </MotionBox>
        <Box as="span" px=".5em">{commentCount}</Box>
      </Flex>
    </Box>
  )
}
