/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Box, Heading, SkeletonText, Text } from '@chakra-ui/react'
import React, { useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { useMeasure } from 'react-use'
import { useHotel } from '../../../gql/queries/getHotel'
// import type { ITagType } from '../../../gql/queries/getTags'
import type { getHotelQuery_getTribelloHotel } from '../../../gql/queries/__generated__/getHotelQuery'
import Interactions from '../../Interactions'

const HotelContent: React.FC<{hotel: getHotelQuery_getTribelloHotel | null | undefined, likeHotel: (value: boolean) => void}> = ({ likeHotel, hotel }) => {
  const [textRef, { height: textHeight }] = useMeasure<HTMLParagraphElement>()
  const spanRef = useRef<HTMLSpanElement>(null)

  const isTextCut = useMemo(() => {
    if (!textHeight || !spanRef.current) {
      return false
    }
    return textHeight < spanRef.current.offsetHeight
  }, [textHeight, spanRef.current])

  if (!hotel) {
    return (
      <Box padding="20px">
        <SkeletonText mt="4" noOfLines={4} spacing="4"/>
      </Box>
    )
  }

  return (
    <Box padding="20px" mb="1em">
      <Heading size="md" mb={2}>{hotel.name}</Heading>
      {hotel.country?.isoCode && <Text opacity={0.5} mt="-3px" mb="5px">{hotel.region} ({hotel.country.isoCode})</Text>}
      <Text ref={textRef} mb="5px" whiteSpace="pre-line" noOfLines={3}>
        <span ref={spanRef} dangerouslySetInnerHTML={{ __html: hotel.description ?? '' }}/>
      </Text>
      {isTextCut && (
        <Text fontSize="sm" mb="10px" opacity={0.5} _hover={{ textDecor: 'underline' }}>
          Mehr lesen
        </Text>
      )}
      <Interactions onLiked={likeHotel} hotel={hotel}/>
    </Box>
  )
}

export default HotelContent
