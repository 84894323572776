import { Box, Button } from '@chakra-ui/react'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import React, { useState } from 'react'
import { useFilterDisclosure } from '../../helper/filterDisclosure'
import Filters from './subComponents/Filters'

const FilterModal: React.FC<{onChange: (tags: string) => void}> = ({ onChange }) => {
  const { isOpen, onClose, onOpen } = useFilterDisclosure()
  const [tags, setTags] = useState<number[]>([])

  if (isOpen === undefined || onClose === undefined) return <Box/>

  return (
    <IonModal
      mode="ios"
      isOpen={isOpen}
      swipeToClose={true}
      onDidDismiss={onClose}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => onClose()}><IonIcon icon={closeCircle}/></IonButton>
          </IonButtons>
          <IonTitle>Filter</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Filters
          showHiddenTagsInSearch={true}
          enableTagCreation={false}
          tags={tags}
          setTags={tags => {
            onChange(JSON.stringify(tags))
            setTags(tags)
          }}
        />
      </IonContent>
    </IonModal>
  )
}

export default FilterModal
