import { gql, useMutation, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { markNotificationAsReadQuery, markNotificationAsReadQueryVariables } from './__generated__/markNotificationAsReadQuery'
import type { markAllNotificationsAsReadQuery, markAllNotificationsAsReadQueryVariables } from './__generated__/markAllNotificationsAsReadQuery'

const PUT_NOTIFICATION_MARK_AS_READ = gql`
  mutation markNotificationAsReadQuery($token: String! $id: Int!) {
    markNotificationAsRead(notificationId: $id, userAccessToken: $token){
      success
    }
  }
`
const PUT_NOTIFICATION_MARK_ALL_AS_READ = gql`
  mutation markAllNotificationsAsReadQuery($token: String!) {
    markAllNotificationsAsRead(userAccessToken: $token ){
      success
    }
  }
`

export function markNotificationAsRead (token: string, id: number) {
  return apolloClient.mutate<markNotificationAsReadQuery, markNotificationAsReadQueryVariables>({
    mutation: PUT_NOTIFICATION_MARK_AS_READ,
    variables: {
      token,
      id,
    },
  })
}

export function useMarkNotificationAsRead (token: string, id: number) {
  return useMutation<markNotificationAsReadQuery, markNotificationAsReadQueryVariables>(PUT_NOTIFICATION_MARK_AS_READ, {
    variables: {
      token,
      id,
    },
  })
}
export function markAllNotificationsAsRead (token: string) {
  return apolloClient.mutate<markAllNotificationsAsReadQuery, markAllNotificationsAsReadQueryVariables>({
    mutation: PUT_NOTIFICATION_MARK_ALL_AS_READ,
    variables: {
      token,
    },
  })
}

export function useMarkAllNotificationsAsRead (token: string) {
  return useMutation<markAllNotificationsAsReadQuery, markAllNotificationsAsReadQueryVariables>(PUT_NOTIFICATION_MARK_ALL_AS_READ, {
    variables: {
      token,
    },
  })
}
