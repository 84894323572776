import { IonContent, IonHeader, IonPage } from '@ionic/react'
import React from 'react'
import Header from '../components/Header'
import Posts from '../components/Posts'

const HotelList: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <Header/>
      </IonHeader>
      <IonContent fullscreen>
        <Posts type="hotel" hideSort={true}/>
      </IonContent>
    </IonPage>
  )
}

export default HotelList
