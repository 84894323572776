import { Box } from '@chakra-ui/react'
import { IonIcon } from '@ionic/react'
import { shareOutline, shareSocialOutline } from 'ionicons/icons'
import React from 'react'
import { MotionFlex } from '../../Motion'

interface ShareProps {
  onClick: () => void
}

const Share: React.FC<ShareProps> = ({ onClick }) => {
  return (
    <MotionFlex
      whileTap={{ scale: 0.8 }}
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      cursor="pointer"
      _hover={{ color: 'blue.400' }}
    >
      <Box as="span" transition="color .35s">
        <IonIcon size="large" ios={shareOutline} md={shareSocialOutline}/>
      </Box>
    </MotionFlex>
  )
}

export default Share
