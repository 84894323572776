import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getTribelloTagTagsByType, getTribelloTagTagsByTypeVariables, getTribelloTagTagsByType_tagsByTagType_types_tags } from './__generated__/getTribelloTagTagsByType'
import type { getTribelloTagTypes } from './__generated__/getTribelloTagTypes'

// TODO: Not automatically generated, needs to be updated manually
export type ITagType = 'region' | 'interest' | 'media' | 'category' | 'type' | 'other' | ''

const GET_ALL_TAG_TYPES = gql`
  query getTribelloTagTypes($showHidden: Boolean!) {
    allTagTypes (showHidden: $showHidden) {
      types{
        name
        description
        id
      }
    }
  }
`

const GET_TAGS_BY_TYPE = gql`
query getTribelloTagTagsByType ($showHidden: Boolean!, $type: String) {
  tagsByTagType(type: $type, showHidden: $showHidden) {
    types {
      tags {
        id
        name
        type {
          name
          description
          id
        }
      }
    }
  }
}
`

export async function getTagsByTagType (showHidden: boolean, type?: string) {
  return apolloClient.query<getTribelloTagTagsByType, getTribelloTagTagsByTypeVariables>({
    query: GET_TAGS_BY_TYPE,
    variables: { type, showHidden },
  })
}

export function useTagsByTagType (showHidden: boolean, type?: string) {
  return useQuery<getTribelloTagTagsByType, getTribelloTagTagsByTypeVariables>(GET_TAGS_BY_TYPE, { variables: { type, showHidden } })
}
// Syntactic sugar for readability
export function useAllTags (showHidden: boolean) {
  return useTagsByTagType(showHidden)
}

export async function getAllTagTypes (showHidden: boolean) {
  return apolloClient.query<getTribelloTagTypes>({
    query: GET_ALL_TAG_TYPES,
    variables: { showHidden },
  })
}

export function useAllTagTypes (showHidden: boolean) {
  return useQuery<getTribelloTagTypes, getTribelloTagTagsByTypeVariables>(GET_ALL_TAG_TYPES, {
    variables: { showHidden },
  })
}

// Legacy; backend is now able to supply this data
export async function getAllTags (showHidden: boolean) {
  const { data } = await getTagsByTagType(showHidden)
  return data.tagsByTagType?.types?.flat().map(e => e?.tags).flat().filter((tag): tag is NonNullable<getTribelloTagTagsByType_tagsByTagType_types_tags | null | undefined> => Boolean(tag?.type?.id))
}
