
import { Container, Heading } from '@chakra-ui/layout'
import { Box, Button, Flex, VStack } from '@chakra-ui/react'
import { css } from '@emotion/css'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import type { FormEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { OnChangeHandlerFunc } from 'react-mentions'
import { useHistory, useParams } from 'react-router'
import { Virtuoso } from 'react-virtuoso'
import Comment from '../components/Comment'
import Header from '../components/Header'
import MentionInput from '../components/MentionInput'
import type { ICardProps } from '../components/Post'
import Post from '../components/Post'
import PostDetailAdvertisement from '../components/PostDetailAdvertisement'
import { useCreateComment } from '../gql/mutations/putComment'
import { getComments } from '../gql/queries/getComments'
import { usePost } from '../gql/queries/getPost'
import type { getCommentsQuery, getCommentsQuery_getTribelloCommentListing_edges } from '../gql/queries/__generated__/getCommentsQuery'
import { getAuthToken, useUser } from '../helper/auth'

function commentLoadFilter (data: getCommentsQuery) {
  const edges = data.getTribelloCommentListing?.edges
  if (!edges) return
  return edges.filter((edge): edge is getCommentsQuery_getTribelloCommentListing_edges => Boolean(edge?.node))
}

const PostDetail: React.FC = () => {
  const { id } = useParams<{id: string}>()
  const [commentText, setCommentText] = useState('')
  const postRes = usePost(id)
  const [comments, setComments] = useState<getCommentsQuery_getTribelloCommentListing_edges[]>([])
  const [createComment, { loading }] = useCreateComment()
  const [hasReachedEnd, setHasReachedEnd] = useState(false)
  const [commentCount, setCommentCount] = useState(0)
  const user = useUser()
  const history = useHistory()

  useEffect(() => {
    setCommentCount(postRes.data?.getTribelloPost?.commentsCount ?? 0)
  }, [postRes.data])

  const handleCommentCreated = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!commentText) return

    const res = await createComment({
      variables: {
        content: commentText,
        postId: Number(id),
        userAccessToken: getAuthToken(),
      },
    })

    if (res.data?.createComment?.comment) {
      setComments([{
        __typename: 'TribelloCommentEdge',
        node: res.data.createComment.comment,
      }, ...comments])
      setCommentCount(commentCount + 1)
    }
    setCommentText('')
  }, [commentText, comments, id, createComment, commentCount])

  const handleCommentDeleted = useCallback((commentId: string) => {
    setComments(comments.filter(comment => comment.node?.id !== commentId))
    setCommentCount(commentCount - 1)
  }, [comments, commentCount])

  const handlePostDeleted = useCallback<NonNullable<ICardProps['onDeleted']>>((post, deletePromise) => {
    history.replace('/posts')

    deletePromise.then(() => {
      history.go(0)
    })
  }, [history])

  const loadMore = useCallback(async () => {
    if (hasReachedEnd) return
    const moreComments = await getComments(id, {
      after: comments.length,
      first: 5,
    })

    const filteredComments = commentLoadFilter(moreComments.data)

    if (filteredComments) {
      setComments([...comments, ...filteredComments])
      setHasReachedEnd(filteredComments.length === 0)
    }
  }, [comments, hasReachedEnd, id])

  const onMentionChange: OnChangeHandlerFunc = useCallback((e, value, plain, mentions) => {
    setCommentText(value)
  }, [])

  const virtualData = useMemo(() => [
    <Container key={`virtual-head-${id}`} maxW="container.md" p="0" >
      <Post isDetail post={postRes.data?.getTribelloPost} commentCount={commentCount} onDeleted={handlePostDeleted}/>
      <VStack
        mb="16px"
        spacing="6"
        align="stretch"
        px="16px"
      >
        {postRes.data?.getTribelloPost?.advertisement?.map(advert => advert && (
          <PostDetailAdvertisement key={advert.id} {...advert}/>
        ))}
        <Box
          id="comment-form"
          borderRadius="8px"
          bg="var(--ion-card-background)"
          p="16px"
        >
          <form onSubmit={handleCommentCreated}>
            <VStack align="stretch">
              <Heading size="sm">Kommentar hinterlassen</Heading>
              <MentionInput
                disabled={!user.isLoggedIn}
                placeholder={!user.isLoggedIn ? 'Einloggen um kommentieren zu können.' : ''}
                value={commentText}
                onChange={onMentionChange}
              />
              <Flex justifyContent="flex-end">
                <Button data-analytics-commet={`post ${postRes.data?.getTribelloPost?.id ?? ''} ${postRes.data?.getTribelloPost?.headline ?? ''}`} colorScheme="primary" type="submit" disabled={!user.isLoggedIn} isLoading={loading}>Antworten</Button>
              </Flex>
            </VStack>
          </form>
        </Box>
      </VStack>
    </Container>,
    ...comments.map((comment, index) => comment.node && (
      <Container
        key={`${id}-comment-${index}`}
        maxW="container.md"
        pb="16px"
      >
        <Comment onDelete={handleCommentDeleted} comment={comment.node}/>
      </Container>
    )),
    <Box key="footing-post" height="55px"/>,
  ], [comments, id, user.isLoggedIn, postRes.data, handlePostDeleted, loading, commentText, handleCommentCreated, handleCommentDeleted, commentCount, onMentionChange])

  return (
    <IonPage>
      <IonHeader>
        <Header showBackButton/>
      </IonHeader>
      <IonContent fullscreen style={{ '--overflow': 'hidden' }}>
        <Virtuoso
          className={css`
            position: absolute !important;
            height: 100% !important;
            width: 100%;
          `}
          components={{ Footer: () => <Box h="56px"/> }}
          data={virtualData}
          overscan={200}
          itemContent={(index, data) => data}
          endReached={loadMore}
        />
      </IonContent>
    </IonPage>
  )
}

export default PostDetail
