
import { Container, Heading, Text } from '@chakra-ui/layout'
import { Box, Button, Center, Modal, ModalBody, Link, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { css } from '@emotion/css'
import { IonIcon, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import { paw, search as searchIcon } from 'ionicons/icons'
import qs from 'query-string'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Virtuoso } from 'react-virtuoso'
import { getMixed, useMixed } from '../gql/queries/getMixed'
import type { getMixedQuery, getMixedQuery_mixedFeed_edges } from '../gql/queries/__generated__/getMixedQuery'
// import { getPosts, usePosts } from '../gql/queries/getPosts'
import type { getPostQuery_getTribelloPost } from '../gql/queries/__generated__/getPostQuery'
import type { getPostsQuery, getPostsQuery_getTribelloPostListing_edges as IPostsEdges } from '../gql/queries/__generated__/getPostsQuery'
import type { IFilter } from '../helper/filterBuilder'
import { filterBuilder } from '../helper/filterBuilder'
import { isJSX } from '../helper/typeguard'
import FilterModal from './FilterModal'
import Hotel from './Hotel'
import Post from './Post'
import { Link as RouterLink } from 'react-router-dom'
import { useRegistrationPopupDetails } from '../gql/queries/getRegistrationPopupDetails'
import { useUser } from '../helper/auth'

function postLoadFilter (data: getPostsQuery): IPostsEdges[] | undefined {
  const edges = data.getTribelloPostListing?.edges
  if (!edges) return
  return edges.filter((edge): edge is IPostsEdges => Boolean(edge?.node))
}

function mixedLoadFilter (data: getMixedQuery): getMixedQuery_mixedFeed_edges[] | undefined {
  const edges = data.mixedFeed?.edges
  if (!edges) return
  return edges.filter((edge): edge is getMixedQuery_mixedFeed_edges => Boolean(edge))
}

interface IPostParams {
  type?: 'hotel' | 'post'
  hideSort?: boolean
  hideFilter?: boolean
  header?: JSX.Element
  filter?: Partial<IFilter>
}

const Posts: React.FC<IPostParams> = ({ type, hideSort = false, hideFilter = false, header, filter: propFilter }) => {
  const [posts, setPosts] = useState<Array<getMixedQuery_mixedFeed_edges | null>>([])
  const [hasReachedEnd, setHasReachedEnd] = useState(false)
  const { search, pathname } = useLocation()
  const [query, setQuery] = useState<IFilter>(qs.parse(search))
  const postsHook = useMixed(filterBuilder({ ...query, ...propFilter, type }, 'mixed'))
  const history = useHistory()
  const [sortBy, setSortBy] = useState('popularity')
  const [displayNotFound, setDisplayNotFound] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const loginPopupDetails = useRegistrationPopupDetails()
  const user = useUser()

  const handleSortChanged = useCallback(async (sort: string, sortDirection?: 'ASC' | 'DESC') => {
    setSortBy(sort)
    const newQuery = { ...query, sort, sortDirection }
    setQuery(newQuery)
    history.push({ search: qs.stringify(newQuery) })

    const sortedPosts = await getMixed(filterBuilder({ first: 10, ...newQuery, ...propFilter, type }, 'mixed'))
    const filteredPosts = mixedLoadFilter(sortedPosts.data)

    if (filteredPosts) {
      setPosts(filteredPosts)
    }
  }, [query, history, type])

  useEffect(() => setQuery(qs.parse(search)), [search])

  useEffect(() => {
    if (!query.sort && pathname === '/posts') handleSortChanged('popularity')
    if (postsHook.data) {
      const filteredPosts = mixedLoadFilter(postsHook.data)
      if (filteredPosts) {
        setPosts(filteredPosts)
      }
      setTotalCount(postsHook.data.mixedFeed?.totalCount ?? 0)

      if (filteredPosts?.length === 0) {
        setDisplayNotFound(true)
      } else {
        setDisplayNotFound(false)
      }
    }
  }, [postsHook.data, query, pathname, handleSortChanged])

  const loadMore = useCallback(async () => {
    if (hasReachedEnd) return
    const morePosts = await getMixed(filterBuilder({ after: posts.length, first: 5, ...query, ...propFilter, type }, 'mixed'))

    const filteredPosts = mixedLoadFilter(morePosts.data)

    if (filteredPosts) {
      setPosts([...posts, ...filteredPosts])
    }
  }, [posts, hasReachedEnd, query, type])

  useEffect(() => {
    setHasReachedEnd(posts.length === 0 || totalCount <= posts.length)
  }, [posts, totalCount])

  const handlePostDeleted = useCallback((post: getPostQuery_getTribelloPost) => {
    setPosts(posts.filter((p) => p?.id !== post.id))
  }, [posts, setPosts])

  const postsVirtData = useMemo(() => {
    if (posts.length !== 0) {
      return [
        header,
        ...posts,
        hasReachedEnd
          ? (
            <Container key="endReached" p="0" maxW="container.md" paddingBottom="150px">
              <Center fontSize="8em" color="primary.500">
                <IonIcon icon={paw}/>
              </Center>
              <Heading textAlign="center">Thats all Folks</Heading>
              <Text textAlign="center">Sieht so aus als hättest du das Ende erreicht.</Text>
            </Container>
            )
          : undefined,
      ].filter(Boolean)
    }

    return [null, null, null]
  }, [posts, hasReachedEnd, header])
  return (
    <Box p="0" position="relative" height="100%" overflowY="hidden">
      {!hideSort && (
        <Container mt={3} maxW="container.md">
          <IonSegment value={sortBy} onIonChange={e => handleSortChanged(e.detail.value!)}>
            <IonSegmentButton value="popularity">
              <IonLabel>Im Trend</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="likesCount">
              <IonLabel>Beste</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="dateTime">
              <IonLabel>Neueste</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </Container>
      )}
      {!hideFilter && (
        <FilterModal onChange={tags => {
          setQuery(prev => ({ ...prev, tags }))
        }}
        />
      )}
      {displayNotFound
        ? (
          <Container key="endReached" p="0" maxW="container.md" pt="30px" paddingBottom="150px" width="100%">
            <Center fontSize="8em" color="primary.500">
              <IonIcon icon={searchIcon}/>
            </Center>
            <Heading textAlign="center">Kein Ergebnis</Heading>
            <Text textAlign="center">Leider wurden keine Beiträge mit diesem Suchfilter gefunden. Setze weniger Filter, um ein besseres Ergebnis zu erzielen.</Text>
          </Container>
          )
        : (
          <Virtuoso
            onScroll={(e: any) => setShowLoginPopup(e.target.scrollTop > 5000)}
            className={css`
              position: absolute !important;
              height: 100% !important;
              width: 100% !important;
              left: 50%;
              transform: translateX(-50%);
            `}
            data={postsVirtData}
            overscan={200}
            endReached={loadMore}
            itemContent={(index, post) => {
              return isJSX(post)
                ? post
                : (
                  <Container p="0" maxW="container.md">
                    {post?.__typename === 'object_TribelloPost' && <Post post={post} onDeleted={handlePostDeleted}/>}
                    {post?.__typename === 'object_TribelloHotel' && <Hotel hotel={post}/>}
                  </Container>
                  )
            }}
          />
          )}
      {!user.isLoggedIn && (
        <Modal
          isCentered
          isOpen={pathname === '/home' && showLoginPopup}
          onClose={() => setShowLoginPopup(false)}
          closeOnOverlayClick={false}
        >
          <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(5px)"
          />
          <ModalOverlay/>
          <ModalContent bg="var(--ion-card-background)">
            <ModalHeader>{loginPopupDetails.data?.getTribelloContentPage?.name}</ModalHeader>
            <ModalBody pb={6} dangerouslySetInnerHTML={{ __html: loginPopupDetails.data?.getTribelloContentPage?.content ?? '' }}/>
            <ModalFooter>
              <RouterLink to="login">
                <Button colorScheme="primary">
                  Jetzt anmelden
                </Button>
              </RouterLink>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

    </Box>
  )
}

export default Posts
