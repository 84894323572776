import { Box, Portal } from '@chakra-ui/react'
import { css } from '@emotion/css'
import { IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import type { FileAddedCallback } from '@uppy/core'
import { Uppy } from '@uppy/core'
import '@uppy/image-editor/dist/style.css'
// @ts-expect-error There are no types...
import German from '@uppy/locales/lib/de_DE'
import { useUppy } from '@uppy/react'
import Tus from '@uppy/tus'
import { add, earthOutline, pawOutline, searchOutline } from 'ionicons/icons'
import React, { useCallback, useMemo, useRef } from 'react'
import { Redirect, Route, useLocation } from 'react-router'
import FileInputButton from '../components/FileInputButton'
import MoreButton from '../components/Navigation/MoreButton'
import UploadModal from '../components/UploadModal'
import { useUser } from '../helper/auth'
import { UPPY_ENDPOINT } from '../helper/enviroment'
import { useFilterDisclosure } from '../helper/filterDisclosure'
import { useUpload } from '../helper/upload'
import BookmarkList from '../pages/BookmarkList'
import Content from '../pages/Content'
import Home from '../pages/Home'
import HotelDetail from '../pages/HotelDetail'
import HotelList from '../pages/HotelList'
import Inspiration from '../pages/Inspiration'
import InspirationDetail from '../pages/InspirationDetail'
import Login from '../pages/Login'
import PostDetail from '../pages/PostDetail'
import Profile from '../pages/Profile'
import ResetPassword from '../pages/ResetPassword'
import Search from '../pages/Search'

export const Router = () => {
  return (
    <IonReactRouter>
      <Tabs/>
    </IonReactRouter>
  )
}

const Tabs: React.FC = () => {
  const { pathname } = useLocation()
  const user = useUser()
  const isHotelsSelected = useMemo(() => pathname.includes('/hotels'), [pathname])
  const filterDisclosure = useFilterDisclosure()
  const parentComponentRef = useRef<HTMLIonRouterOutletElement | null>(null)
  const modalState = useUpload()

  const debugLogger = useMemo(() => ({
    debug: (...args: any) => console.debug('[Uppy]', ...args),
    warn: (...args: any) => console.warn('[Uppy]', ...args),
    error: (...args: any) => console.error('[Uppy]', ...args),
  }), [])
  const uppy = useUppy(() => {
    return new Uppy({
      logger: debugLogger,
      locale: German,
      restrictions: {
        allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv'],
        maxNumberOfFiles: 10,
        maxFileSize: 300 * 1024 * 1024,
      },
    })
      .use(Tus, {
        endpoint: UPPY_ENDPOINT,
        headers: { Authorization: `Bearer ${user.token ?? ''}` },
      })
  })

  const onFileAdded = useCallback<FileAddedCallback<Record<string, unknown>>>((file) => {
    if (!modalState.uploadModalOpen) {
      modalState.setUploadModalOpen?.(true)
    }
    uppy.getPlugin('react:Dashboard')?.setOptions({
      disabled: false,
    })
  }, [modalState, uppy])

  const handleDismiss = useCallback(() => {
    uppy.reset()
    modalState.setFiles?.([])
    modalState.setUploadModalOpen?.(false)
  }, [modalState])

  return (
    <>
      <Portal>
        <UploadModal uppy={uppy} files={modalState.files ?? []} onDismiss={handleDismiss} presentingElemet={parentComponentRef.current} isOpen={modalState.uploadModalOpen ?? false}/>
      </Portal>
      <IonTabs>
        <IonRouterOutlet ref={parentComponentRef}>
          <Route exact path="/home">
            <Home/>
          </Route>
          <Route exact path="/search">
            <Search/>
          </Route>
          <Route exact path="/saved">
            <BookmarkList/>
          </Route>
          <Route exact path="/posts/:id">
            <PostDetail/>
          </Route>
          <Route exact path="/hotels">
            <HotelList/>
          </Route>
          <Route exact path="/hotels/:id">
            <HotelDetail/>
          </Route>
          <Route exact path="/inspirations">
            <Inspiration/>
          </Route>
          <Route exact path="/inspirations/:id">
            <InspirationDetail/>
          </Route>
          <Route exact path="/profile/:userId">
            <Profile/>
          </Route>
          <Route exact path="/login">
            <Login/>
          </Route>
          <Route exact path="/forgot/:hash?">
            <ResetPassword/>
          </Route>
          <Route exact path="/content/:path">
            <Content/>
          </Route>
          <Route exact path="/">
            <Redirect to="/home"/>
          </Route>
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          mode="md"
          className={css`
            position: absolute;
            bottom: 0;
            width: 100%;
            backdrop-filter: blur(10px);
            background: var(--ion-tab-bar-background);
            --background: transparent;
          `}
        >
          <IonTabButton tab="home" routerOptions={{ unmount: true }} href="/home">
            <IonIcon icon={pawOutline}/>
          </IonTabButton>
          <IonTabButton
            tab="search"
            href={isHotelsSelected ? '/hotels' : '/home'}
            routerOptions={{ unmount: true }}
          >
            <IonIcon icon={searchOutline}/>
            <Box
              w="100%"
              h="100%"
              pos="absolute"
              onClick={filterDisclosure.onOpen}
              zIndex="10"
            />
          </IonTabButton>
          {(!user.loading && user.isLoggedIn) && (
            <IonTabButton
              className={css`
                padding: 0;
              `}
              tab="upload"
              routerOptions={{ unmount: true }}
            >
              <FileInputButton
                uppy={uppy}
                id="upload-media-tabbar"
                events={{
                  'file-added': onFileAdded,
                  error: (e) => console.error(e),
                }}
              >
                <IonIcon
                  className={css`
              transform: scale(2);
            `}
                  icon={add}
                />
              </FileInputButton>
            </IonTabButton>
          )}
          <IonTabButton tab="placeholder" routerOptions={{ unmount: true }} href="/hotels">
            <IonIcon icon={earthOutline}/>
          </IonTabButton>
          <IonTabButton
            tab="more"
            className={css`
                padding: 0;
              `}
          >
            <MoreButton/>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  )
}
