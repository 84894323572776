import { Avatar, Badge, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, useDisclosure } from '@chakra-ui/react'
import { IonBackButton, IonButton, IonButtons, IonIcon, IonTitle, IonToolbar, useIonRouter } from '@ionic/react'
import { notificationsOutline } from 'ionicons/icons'
import React, { useCallback } from 'react'
import { markAllNotificationsAsRead, markNotificationAsRead } from '../gql/mutations/putNotificationMarkAsRead'
import { getCompressedImageUrl } from '../helper/asset'
import { useUser } from '../helper/auth'
import { useNotifications } from '../helper/notifications'
import Notification from './Notification'

interface IHeaderProps {
  title?: string
  showBackButton?: boolean
}

const Header: React.FC<IHeaderProps> = ({ showBackButton, title }) => {
  const notifications = useNotifications()
  const user = useUser()
  const router = useIonRouter()
  const popoverState = useDisclosure()

  const markAsRead = useCallback((id: number) => {
    if (user.token) markNotificationAsRead(user.token, id)
    popoverState.onClose()
  }, [user.token, popoverState])

  const markAllAsRead = useCallback(() => {
    if (user.token) markAllNotificationsAsRead(user.token)
  }, [user.token])

  return (
    <IonToolbar>
      {title && <IonTitle>{title}</IonTitle>}
      {showBackButton && (
        <IonButtons slot="start">
          <IonBackButton defaultHref="/"/>
        </IonButtons>
      )}
      <IonButtons slot="end">
        {user.data && (
          <>
            {!!notifications.totalUnread && notifications.totalUnread > 0 && (
              <Badge pos="absolute" fontSize="10px" h="15px" minW="15px" borderRadius="50%" top="5px" left="25px" variant="solid" bg="red.500" zIndex={100}>
                {notifications.totalUnread}
              </Badge>
            )}
            <Popover {...popoverState}>
              <PopoverTrigger>
                <IonButton>
                  <IonIcon slot="icon-only" icon={notificationsOutline}/>
                </IonButton>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow/>
                  <PopoverBody bg="var(--ion-card-background)" p="0">
                    {notifications.data?.sort((a, b) => Number(b?.creationDate) - Number(a?.creationDate)).map((notification) => (
                      notification && <Notification key={notification.id} markAsRead={() => markAsRead(Number(notification.id))} {...notification}/>
                    ))}
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
            <IonButton onClick={() => router.push(user.data?.user?.id ? `/profile/${user.data.user.id}` : '/login')}>
              <Avatar size="sm" slot="icon-only" src={getCompressedImageUrl(user.data.user?.customer?.images?.[0]?.fullpath)}/>
            </IonButton>
          </>
        )}
      </IonButtons>
    </IonToolbar>

  )
}

export default Header
