import { Box, Flex, Image, Skeleton, useColorModeValue } from '@chakra-ui/react'
import { IonIcon } from '@ionic/react'
import type { AnimationControls } from 'framer-motion'
import { heart } from 'ionicons/icons'
import React, { useState } from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar, Virtual, Zoom } from 'swiper'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/scrollbar/scrollbar.min.css'
import 'swiper/modules/zoom/zoom.min.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import type { getInspirationListQuery_getInspirationListing_edges_node as IInspiration } from '../../../gql/queries/__generated__/getInspirationListQuery'
import { getCompressedImageUrl } from '../../../helper/asset'
import { MotionFlex } from '../../Motion'

SwiperCore.use([Zoom, Navigation, Pagination, Virtual, Scrollbar])

const InspirationMedia: React.FC<{inspiration: IInspiration | null | undefined, animationControl?: AnimationControls }> = ({ inspiration, animationControl }) => {
  const imageBg = useColorModeValue('gray.50', '#181818')
  const [activeIndex, setActiveIndex] = useState(0)

  if (!inspiration) return <Skeleton h={['200px', '300px', '400px']} w="100%"/>
  if (!inspiration.images) return null

  return (
    <Box onClick={e => e.stopPropagation()} bg={imageBg}>
      <Swiper
        zoom={{
          maxRatio: 5,
          minRatio: 1,
        }}
        scrollbar={{ hide: true }}
        navigation
        style={{ width: '100%' }}
        virtual={true}
        preloadImages={false}
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {
          inspiration.images.map((image, index) => image?.image?.fullpath && image.image.mimetype && (
            <SwiperSlide key={index}>
              <Flex
                position="relative"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                cursor="pointer"
              >
                <MotionFlex
                  initial={{ opacity: 0 }}
                  animate={animationControl}
                  pointerEvents="none"
                  position="absolute"
                  color="red.400"
                  fontSize="150px"
                  h="100%"
                  w="100%"
                  top="0"
                  left="0"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={3}
                >
                  <IonIcon icon={heart}/>
                </MotionFlex>
                <Box className="swiper-zoom-container" h={['200px', '300px', '400px']}>
                  <Image w="100%" h="100%" src={getCompressedImageUrl(image.image.fullpath)} key={index}/>
                </Box>
              </Flex>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </Box>
  )
}

export default InspirationMedia
